<template>
  <base-section
    id="map"
    space="0"
  >
    <iframe
      allowfullscreen
      aria-hidden="false"
      frameborder="0"
      height="500"
      src="https://www.openstreetmap.org/export/embed.html?bbox=7.044923901557923%2C47.27811716612645%2C7.0484644174575815%2C47.279791280846005&amp;layer=mapnik&amp;marker=47.27895423010753%2C7.0466941595077515"
      style="border:0; display: block;"
      tabindex="0"
      width="100%"
    />
  </base-section>
</template>

<script>
  export default {
    name: 'SectionMap',

    provide: {
      heading: { align: 'center' },
    },
  }
</script>
